import React from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import "./pdpa.css";

export default function PDPA() {
  return (
    <div>
      <h1>นโยบายความเป็นส่วนตัว</h1>

      <section className="section-partnerships">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-lg-auto">
              <div>
                บริษัท ไอแคร์ เฮลท์ จำกัด (“บริษัท”)
                ในฐานะผู้ควบคุมข้อมูลส่วนบุคคล เคารพความเป็นส่วนตัว
                และได้ตระหนักถึงความสำคัญของนโยบายความเป็นส่วนตัวของท่าน
                ในฐานะผู้ใช้บริการ สำหรับการใช้งาน เว็บแอพพลิเคชั่นนี้
                บริษัทจึงได้จัดทำนโยบายความป็นส่วนตัว (“นโยบาย”)
                โดยครอบคลุมวิธีการบอกให้ท่านทราบถึงวิธีที่ร้านค้าหรือแบรนด์
                หรือบริษัทพันธมิตร บริษัทในเครือ รวมทั้งบริษัทผู้พัฒนาระบบ
                (บริษัท ไอแคร์ เฮลท์ จำกัด ) (รวมเรียกว่า “บริษัทฯ” หรือ “เรา”)
                จะดำเนินการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน (“ผู้ใช้งาน”หรือ “ท่าน”)
                ผ่านการใช้งานเว็บแอพพลิเคชั่นนี้ บนเว็ปไซต์
                หรือโทรศัพท์มือถือทั้งในระบบ Android และ IOS (“ระบบ”)
                โดยนโยบายนี้ทางบริษัทฯ
                ได้จัดทำขึ้นเพื่อชี้แจงรายละเอียดวิธีการจัดการเกี่ยวกับข้อมูลส่วนบุคคล
                โดยการเก็บข้อมูลนั้นมีวัตถุประสงค์หลัก เพื่อพัฒนาการให้บริการที่ดียิ่งขึ้น
                ดังนั้นการที่ท่านเข้าไปใช้งานและใช้บริการบนระบบดังกล่าวนั้น
                หมายความว่าท่านได้ยินยอมให้บริษัทฯ
                จัดการข้อมูลของท่านในลักษณะและเพื่อวัตถุประสงค์ที่ระบุในนโยบายนี้
                ดังนี้
              </div>

              <br />

              <div className="number-title-32">
                1. ขอบเขตนโยบายและความยินยอม
                ท่านได้อ่านโดยละเอียดและเข้าใจว่านโยบายความเป็นส่วนตัวนี้ใช้บังคับและครอบคลุมการใช้งาน 
                หรือบริการต่างๆ การที่ท่านเข้าถึงเว็บแอพพลิเคชั่น 
                เป็นการถือว่าท่านอนุญาตและตกลงให้ความยินยอมต่อบริษัทฯ 
                ในการรวบรวมและใช้ข้อมูลส่วนบุคคลของท่านที่สอดคล้องกับนโยบายความเป็นส่วนตัวนี้ 
                และนโยบายอื่นๆ ของบริษัทฯ เพื่อการปรับปรุงหรือพัฒนาการให้บริการ 
                และการต่าง ๆ ตามข้อ 3.1 รวมถึงการอื่นๆ ตามที่บริษัทฯ เห็นสมควร
              </div>

              <div className="number-title-32">
                2. ข้อมูลที่บริษัทฯ เก็บรวบรวมเกี่ยวกับคุณ
                บริษัทฯ รวบรวมข้อมูลส่วนบุคคลต่อไปนี้จากท่าน ซึ่งได้มอบให้แก่บริษัทฯ ณ 
                วันที่ท่านได้ใช้บริการของบริษัทฯ 
                บนพื้นฐานความสมัครใจของท่านทุกประการ
              </div>



              <div className="number-title-48">
                2.1 ข้อมูลสมาชิก: การสมัครสมาชิกหรือใช้งาน เว็บแอพพลิเคชั่น 
                ทำได้ 2 วิธี ดังนี้ 
                </div>

              <div className="number-title-70">
                (1)
                ท่านสามารถสร้างบัญชีสมาชิกได้ด้วยตนเองโดยการให้ข้อมูลต่างๆ 
                แก่ระบบ ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล เพศ  วันเดือนปีเกิด 
                สถานภาพ ที่อยู่ ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชน 
                หนังสือเดินทาง ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่ 
                ช่องทางติดต่อในโซเชียลมีเดีย รหัสผ่าน และ/หรือข้อมูลอื่นๆ 
                ที่เกี่ยวข้อง เช่น รูปถ่ายของท่าน (รูปโปรไฟล์) หรือ
                </div>

              <div className="number-title-70">
                (2) ผ่าน web browser 
                โดยพนักงานหน้าร้านเป็นผู้สร้างบัญชีสมาชิกให้ 
                โดยการให้ข้อมูลต่างๆ แก่ระบบ ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล เพศ  
                วันเดือนปีเกิด สถานภาพ ที่อยู่ 
                ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชน หนังสือเดินทาง 
                ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่ 
                ช่องทางติดต่อในโซเชียลมีเดีย หรือข้อมูลที่ท่านแจ้ง 
                ซึ่งท่านจะต้องทำการตรวจสอบข้อมูลของท่านให้ถูกต้อง ตรงตามปัจจุบัน 
                โดยการให้ข้อมูลส่วนบุคคลดังกล่าวนั้น ท่านตกลงและให้ความยินยอมแก่ 
                บริษัทฯ ในการตรวจสอบ เก็บรวบรวมและใช้ข้อมูลส่วนบุคคลนั้นๆ
                </div>

              <div className="number-title-48">
                2.2
                ข้อมูลเกี่ยวกับการสั่งซื้อสินค้า หรือแลกของรางวัล: 
                ท่านยินยอมให้บริษัทฯ จัดเก็บและใช้งานข้อมูลการเลือกดูสินค้า 
                ข้อมูลการสั่งซื้อสินค้า ข้อมูลการเลือกดูของรางวัล 
                ข้อมูลการแลกของรางวัล และ/หรือข้อมูลที่เกี่ยวข้องอื่นๆ 
                เพื่อการให้บริการด้านการขาย และการนำเสนอโปรโมชันต่างๆ 
                แก่ท่านได้อย่างถูกต้องและมีประสิทธิภาพ
                </div>

              <div className="number-title-48">
                2.3
                ข้อมูลสถานะผู้ใช้งานและประวัติการใช้งาน: ท่านยินยอมให้บริษัทฯ 
                ตรวจสอบและใช้งานข้อมูลสถานะปัจจุบันของท่านเช่น 
                ข้อมูลประเภทสมาชิก ข้อมูลแต้มสะสมในปัจจุบัน 
                ข้อมูลประวัติการได้รับการใช้แต้ม 
                ข้อมูลประวัติการสั่งซื้อสินค้าและการแลกรับของรางวัล เป็นต้น 
                เพื่อการแสดงผลข้อมูลในระบบ และการวิเคราะห์ข้อมูลในอนาคต
                </div>

              <div className="number-title-48">
                2.4
                ข้อมูลการตอบแบบสอบถาม: เมื่อท่านทำการตอบแบบสอบถามใดๆ 
                ที่เกิดขึ้นในระบบ ท่านตกลงให้บริษัทฯ สามารถทำการจัดเก็บ ตรวจสอบ 
                วิเคราะห์ ข้อมูลนั้นๆ เพื่อการส่งเสริมการตลาด 
                การพัฒนาผลิตภัณฑ์และการบริการ หรือวัตถุประสงค์อื่นใด 
                ที่ได้ระบุไว้ในนโยบายนี้
                </div>

              <div className="number-title-48">
                2.5
                ข้อมูลจากอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้: บริษัทฯ 
                อาจรวบรวมข้อมูลจากอุปกรณ์สื่อสารของผู้ใช้งาน 
                และที่มีการถูกบันทึกไว้บนเซิฟเวอร์ของบริษัทฯ โดยอัตโนมัติ 
                ซึ่งรวมถึงไอพีแอดเดรส คุณสมบัติของซอฟต์แวร์และฮาร์ดแวร์ 
                ข้อมูลระบบปฏิบัติการ หมายเลขประจำเครื่อง ข้อมูลทางภูมิศาสตร์ 
                พิกัดที่ตั้งของอุปกรณ์ เป็นต้น
                </div>

              <div className="number-title-48">
                2.6 ข้อมูลการใช้งานระบบ: บริษัทฯ 
                อาจเก็บข้อมูลการใช้งานระบบของผู้ใช้งาน เช่น วันเวลาการใช้งานระบบ 
                กิจกรรมการใช้งานระบบ การตั้งค่าระบบ การตั้งค่าภาษา 
                เวอร์ชั่นของระบบ เป็นต้น 
              </div>


              <div className="number-title-32">
                3. การใช้ข้อมูล
                </div>

              <div className="number-title-48">
                3.1 บริษัทฯ อาจใช้ข้อมูลที่บริษัทฯ 
                เก็บรวบรวมและควบคุมไว้เพื่อวัตถุประสงค์ต่อไปนี้
                </div>

              <div className="number-title-70">
                (1)
                เพื่อดูแลและให้บริการการใช้งานระบบแก่ท่านได้อย่างถูกต้อง 
                ราบรื่นและมีประสิทธิภาพ
                </div>

              <div className="number-title-70">
                (2) เพื่อสร้างและบริหารจัดการบัญชีสมาชิก 
                เพื่อแก้ไขปัญหาด้านเทคนิค และ/หรือเพื่อเปิดใช้งานฟีเจอร์ต่างๆ
                </div>

              <div className="number-title-70">
                (3) เพื่อออกแบบสอบถามความคิดเห็น 
                นำเสนอโปรโมชันหรือให้ข้อมูลแก่ท่านเกี่ยวกับผลิตภัณฑ์และบริการของเรา 
                ให้ตรงกับความต้องการของท่านให้มากที่สุด
                </div>

              <div className="number-title-70">
                (4)
                เพื่อดูแลกิจกรรมการใช้งานโดยทั่วไปและกิจกรรมการใช้งานของผู้ใช้งานรายบุคคล
                เช่น การเลือกดูสินค้า การสั่งซื้อสินค้า การเลือกดูโปรโมชั่น
                หรือการแลกของรางวัล เป็นต้น เพื่อดำเนินการจัดส่งสินค้า
                หรือของรางวัลให้แก่ท่านได้อย่างถูกต้อง
                </div>

              <div className="number-title-70">
                (5) เพื่อติดต่อผู้ใช้งาน
                สำหรับการให้บริการ การดูแลผู้ใช้งาน
                หรือสำหรับการสื่อสารด้านการตลาด
                ที่ได้รับอนุญาตผ่านช่องทางติดต่อสื่อสารใดๆ ที่ใช้การได้
                </div>

              <div className="number-title-70">
                (6)
                เพื่อออกแบบประสบการณ์การใช้งาน 
                การปรับปรุงระบบรวมทั้งการพัฒนาผลิตภัณฑ์และการบริการอื่นๆ 
                ในอนาคตของบริษัทฯ
                </div>

              <div className="number-title-70">
                (7) เพื่อบังคับใช้นโยบายการใช้งาน 
                ข้อกำหนดและเงื่อนไขต่างๆ ของบริษัทฯ รวมถึงการตรวจสอบ 
                การละเมิดที่อาจเกิดขึ้นเพื่อตรวจจับ 
                ป้องกันมิจฉาชีพหรือการใช้งานในทางที่ผิด ซึ่งเป็นอันตรายต่อสิทธิ 
                ทรัพย์สิน ความปลอดภัยของท่านหรือบริษัทฯ 
                </div>

              <div className="number-title-70">
                (8)
                เพื่อให้ความร่วมมือกับกระบวนการยุติธรรม การบังคับใช้กฎหมาย 
                ตามที่หน่วยงานและพนักงานเจ้าหน้าที่ของรัฐหรือศาล 
                มีคำขอหรือหนังสือเรียกร้องทางกฎหมาย
                </div>

              <div className="number-title-48">
                3.2
                ท่านอนุญาตและให้ความยินยอมแก่บริษัทฯ ในการที่บริษัทฯ 
                อาจให้หรือแบ่งปันข้อมูลเกี่ยวกับท่านกับบุคคลภายนอก เช่น 
                ผู้ให้บริการด้านการตลาดหรือการวิเคราะห์ 
                ทำการเก็บรวบรวมข้อมูลต่างๆ 
                ซึ่งข้อมูลที่บุคคลดังกล่าวได้ทำการเก็บรวบรวม 
                จะอยู่ภายใต้บังคับนโยบายความเป็นส่วนตัวฉบับนี้ 
                และของบุคคลดังกล่าวด้วย 
                </div>

              <div className="number-title-48">
                3.3 บริษัทฯ
                อาจเก็บรักษาข้อมูลที่ได้รวบรวมไว้
                รวมถึงสังเกตการณ์บนเครือข่ายและในฐานข้อมูลของบริษัทฯ
                ได้นานเท่าที่จำเป็น เพื่อให้บรรลุวัตถุประสงค์ที่ระบุไว้ข้างต้น
                </div>

              <div className="number-title-48">
                3.4 บริษัทฯ 
                ไม่มีวัตถุประสงค์ในการใช้ข้อมูลเพื่อการอื่นอันเป็นการนอกเหนือจากที่กล่าวมาแล้วข้างต้น 
                โดยบริษัทฯ 
                จะเก็บรักษาข้อมูลของผู้ใช้งานตามความจำเป็นและตามที่กฎหมายกำหนดไว้
                </div>


              <div className="number-title-32">
                4. การแบ่งปันข้อมูล
                </div>
              <div className="number-title-48">
                4.1 บริษัทฯ อาจแบ่งปันข้อมูลที่บริษัทฯ 
                เก็บรวบรวมไว้ กับบริษัทในเครือ (บริษัทที่อยู่ภายใต้การครอบครอง 
                หรือความควบคุมเดียวกัน) บริษัทร่วมให้บริการ บริษัทผู้พัฒนาระบบ 
                พันธมิตรทางธุรกิจ หรือบริษัทวิเคราะห์ข้อมูลการตลาด 
                ซึ่งตั้งอยู่ในประเทศไทยหรือในประเทศอื่น โดยบริษัทดังกล่าว 
                จะประมวลผลและใช้ข้อมูลในการช่วยเหลือบริษัทฯ 
                เพื่อวัตถุประสงค์ที่ระบุไว้ข้างต้น 
                และจะปฏิบัติตามนโยบายความเป็นส่วนตัวนี้ 
                </div>
              <div className="number-title-48">
                4.2 พนักงานของบริษัทฯ 
                หรือผู้มีสิทธิ์เข้าถึงข้อมูลนี้โดยได้รับอนุญาตจากบริษัทฯ 
                หรือผู้ที่จำเป็นต้องทราบหรือเข้าถึงข้อมูลนี้ 
                (รวมถึงพันธมิตรทางธุรกิจ และบริษัทร่วมให้บริการ) 
                เพื่อให้บริการที่ท่านร้องขอ 
                จะสามารถเข้าถึงข้อมูลส่วนตัวของท่านเท่าที่จำเป็น 
                เพื่อการดำเนินการและให้บริการแก่ท่าน เช่น 
                การตรวจสอบคำสั่งซื้อสินค้า การยืนยันการจัดส่งสินค้า 
                การดูแลเรื่องการจัดการของรางวัล การตอบข้อสงสัย 
                หรือการตรวจสอบการร้องเรียนต่างๆ ที่อาจะเกิดขึ้น เป็นต้น
</div>
              <div className="number-title-48">
                4.3
                บริษัทฯ มีมาตราการป้องกันที่เหมาะสม 
                สำหรับการแบ่งปันข้อมูลดังกล่าว 
                โดยเป็นไปตามกฎหมายที่บังคับใช้กำหนด 
                เพื่อห้ามมิให้บุคคลที่สามใช้ข้อมูลของท่านเพื่อวัตถุประสงค์ส่วนตัว 
                เพื่อดูแลความปลอดภัยของข้อมูลของท่าน 
                และเพื่อเก็บข้อมูลของท่านเป็นความลับ 
                นอกจากจะได้รับการเปิดเผยในนโยบายความเป็นส่วนตัวนี้ 
                หรือเป็นไปตามทกฎหมายที่บังคับใช้กำหนดหรืออนุญาต 
                เราจะไม่เปิดเผยข้อมูลของท่านให้แก่บุคคลที่สามโดยที่ท่านมิได้ให้ความยินยอม 
                เว้นแต่จะได้รับอนุญาตอย่างเฉพาะเจาะจงจากท่านให้กระทำการนั้นได้ 
                โดยท่านตกลงและยินยอมให้ บริษัทฯ 
                สามารถเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่าน 
                ในกรณีดังต่อไปนี้:
                <div className="number-title-70">
                  (1) บริษัทฯ สามารถแบ่งปัน เปิดเผย ส่ง โอน 
                  ข้อมูลหมายเลขโทรศัพท์ของท่าน ไปยังหรือให้แก่ผู้บริการ 
                  โครงข่ายโทรคมนาคมหรือโทรศัพท์เคลื่อนที่ได้ 
                  ทั้งนี้เพื่อให้บริษัทฯ และระบบสามารถยืนยันตัวตนของท่านได้ 
                </div>
                <div className="number-title-70">
                  (2)
                  บริษัทฯ 
                  อาจเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านกับหน่วยงานภาครัฐผู้มีอำนาจตามคำสั่งหนังสือ 
                  หรือกฎหมายที่เกี่ยวข้องได้กำหนดไว้ 
                </div>
              </div>







              <div className="number-title-32">
                5. การควบคุมโดยผู้ใช้งาน การเข้าถึง การแก้ไข การสั่งซื้อ 
                การแลกของรางวัล หรือการกระทำใดๆ ในระบบ 
                ท่านมีหน้าที่รับผิดชอบในการป้องกันการเข้าถึงและรักษาข้อมูลในบัญชีสมาชิกให้ท่านสามารถเข้าถึงข้อมูลในระบบได้เพียงผู้เดียวเท่านั้น 
                การกระทำใดๆ ในนามผู้ใช้งาน บริษัทฯ 
                ถือว่าเป็นการกระทำที่ถูกต้องโดยสุจริต 
                ที่จะปฏิเสธการกระทำที่เกิดขึ้นไม่ได้ 
                เว้นเสียจะเป็นความผิดพลาดที่เกิดขึ้นจากระบบเอง บริษัทฯ 
                จะทำการตรวจสอบ และดำเนินการแก้ไขให้ถูกต้องตามสมควร
              </div>
              <div className="number-title-32">
                6. อุปกรณ์พกพา ด้วยความยินยอมของท่าน 
                เราอาจมีการส่งข้อความแจ้งเตือนซึ่งระบุข้อมูลสินค้าใหม่ 
                ข้อมูลโปรโมชัน ส่วนลด การได้รับคะแนนพิเศษ 
                สถานะการสั่งสินค้าหรือการจัดส่ง ข้อมูลอื่นๆ 
                ที่มีหรืออาจมีในอนาคตได้ 
                ซึ่งการแจ้งเตือนถือเป็นส่วนหนึ่งของการใช้งานระบบ 
                กรุณาอ่านคู่มือการใช้งานอุปกรณ์พกพาของท่าน 
                เพื่อทำความเข้าใจเกี่ยวกับวิธีการเปลี่ยนแปลงการตั้งค่า 
                หรือเปิดรับ (หรือปิดรับ) การแจ้งเตือน (ซึ่งรวม Software 
                Development Kit (“SDK”) และข้อมูล Push Token) 
                ระบบปฏิบัติการของอุปกรณ์ที่แตกต่างกันอาจมีการตั้งค่าเริ่มต้นที่แตกต่างกัน 
                ดังนั้นกรุณาทำความเข้าใจเกี่ยวกับการตั้งค่าการแจ้งเตือนดังกล่าว
              </div>
              <div className="number-title-32">
                7. การรักษาความปลอดภัยของข้อมูลส่วนบุคคล
                </div>

              <div className="number-title-48">
                7.1 ข้อมูลที่บริษัทฯ 
                เก็บรวบรวมจะได้รับการคุ้มครองโดยวิธีทางเทคนิคและขั้นตอนการรักษาความปลอดภัยที่เหมาะสมตามสมควร 
                เพื่อป้องกันการเข้าถึงหรือการใช้ข้อมูลโดยไม่ได้รับอนุญาต
                </div>

              <div className="number-title-48">
                7.2
                บริษัทฯ 
                จะเก็บรักษาข้อมูลของท่านในระยะเวลาเท่าที่จำเป็นสำหรับการบรรลุวัตถุประสงค์ที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้และนโยบายภายในของบริษัทฯ 
                เมื่อบริษัทฯ ไม่จำเป็นต้องใช้ข้อมูลของท่านแล้ว บริษัทฯ 
                จะทำการลบข้อมูลของท่านออกจากระบบและบันทึกของบริษัทฯ และ/หรือ 
                ดำเนินการอย่างเหมาะสมในการทำให้ข้อมูลดังกล่าวไม่สามารถระบุตัวตนของท่านได้ 
                เว้นแต่เมื่อจำเป็นต้องเก็บรักษาข้อมูลของท่านไว้ 
                เพื่อปฏิบัติตามกฎหมายหรือข้อผูกพันที่บังคับใช้ 
                หรือเมื่อจำเป็นต้องใช้ข้อมูลในการปฏิบัติภารกิจขององค์กรให้แล้วเสร็จและบรรลุเป้าหมายทางธุรกิจ
                </div>

              <div className="number-title-48">
                7.3
                เพื่อความปลอดภัยในข้อมูลส่วนบุคคลและความเป็นส่วนตัวในช่องทางติดต่อสื่อสารของท่าน 
                ท่านตกลงปฏิบัติตามนโยบายความเป็นส่วนตัวนี้ 
                รวมถึงข้อกำหนดและเงื่อนไขการใช้งานหรือบริการต่างๆ ของบริษัทฯ 
                อย่างเคร่งครัด โดยท่านตกลงจะไม่กระทำการใดอันเป็นการรบกวน 
                หรือละเมิดสิทธิของบริษัทฯ และ/หรือผู้ใช้งานอื่น 
                และ/หรือบุคคลภายนอก หากบริษัทฯ 
                ทราบหรือพบว่ามีการละเมิดสิทธิต่อบริษัทฯ หรือต่อผู้อื่น บริษัทฯ 
                ขอสงวนสิทธิ์ในการยกเลิกและปิดกั้นการเข้าใช้งานหรือบริการของผู้ใช้งานผู้กระทำการละเมิดนั้น 
                รวมถึงการปิดบัญชีสมาชิกของผู้ใช้งานนั้นและจะดำเนินคดีตามกฎหมายจนถึงที่สุดโดยไม่จำเป็นต้องแจ้งให้ท่านทราบล่วงหน้า 
                โดยบริษัทฯ สามารถแจ้งให้ผู้ใช้งานทราบในเหตุนั้นๆ 
                ที่เกิดขึ้นในภายหลังได้
                </div>

              <div className="number-title-48">
                7.4 ผู้ใช้งานรับทราบและยินยอมว่า 
                ถึงแม้บริษัทฯ 
                จะมีมาตรการในการจัดเก็บข้อมูลของท่านอย่างปลอดภัยแต่อาจเกิดกรณีที่ข้อมูลส่วนบุคคลและช่องทางติดต่อสื่อสารของผู้ใช้งาน 
                เสียหายหรือสูญหาย ซึ่งอาจเกิดจากเหตุสุดวิสัย หรือจากสาเหตุใดๆ 
                ซึ่งรวมถึงการจารกรรมด้วยวิธีการทางอิเล็กทรอนิกส์ ที่อาจเกิดขึ้น 
                บริษัทฯ 
                จึงขอสงวนสิทธิ์โดยไม่ต้องรับผิดชอบต่อความเสียหายหรือความสูญหายดังกล่าว 
                หรือต่อความเสียหายสืบเนื่องใดๆ ทั้งสิ้น 
                </div>

              <div className="number-title-32">
                8. วิธีการใช้สิทธิหรือการเปลี่ยนแปลงข้อมูล
                </div>
              <div className="number-title-48">
                • การเข้าถึงข้อมูล: 
                ท่านมีสิทธิ์ขอข้อมูลส่วนตัวของท่านที่เราเป็นผู้จัดเก็บได้ทุกเมื่อ 
                โดยเป็นไปตามกฎหมายที่บังคับใช้และไม่มีค่าธรรมเนียมใดๆ 
                อย่างไรก็ตาม ในบางกรณีหากกฎหมายที่บังคับใช้อนุญาต บริษัทฯ 
                อาจขอให้ท่านชำระค่าธรรมเนียมตามความเหมาะสม ก่อนที่ทางบริษัทฯ 
                จะจัดหาข้อมูลดังกล่าว
                </div>
              <div className="number-title-48">
                • การแก้ไขข้อมูล: ท่านสามารถขอให้บริษัทฯ 
                แก้ไขข้อมูลส่วนตัว ของท่านซึ่งไม่ครบถ้วนหรือไม่ถูกต้องได้

                </div>
              <div className="number-title-48">
                •
                การลบข้อมูล: ท่านสามารถขอให้บริษัทฯ ลบบัญชี และ/หรือ 
                ข้อมูลส่วนบุคคลของท่านได้
                </div>
              <div className="number-title-48">
                • การจำกัดการจัดเก็บข้อมูล: 
                ท่านสามาถขอให้บริษัทฯ ยุติการจัดเก็บข้อมูลส่วนตัว 
                หากท่านได้ทำการคัดค้านการจัดเก็บข้อมูลโดยมูลเหตุที่ชอบด้วยกฎหมายและยังคงอยู่ในระหว่างการพิจารณา 
                เป็นต้น 
                </div>
              <div className="number-title-48">
                • การคัดค้านการจัดเก็บข้อมูล: 
                ท่านมีสิทธิ์ในการคัดค้านการจัดเก็บข้อมูลส่วนตัวของท่านทุกเมื่อ 
                ซึ่งเป็นไปเพื่อวัตถุประสงค์ของการตลาดทางตรง
                </div>
              <div className="number-title-48">
                •
                สิทธิ์ในการเพิกถอนความยินยอม: 
                ท่านมีสิทธิ์ในการเพิกถอนความยินยอมทุกเมื่อ 
                โดยต้องไม่มีผลกระทบต่อการปฏิบัติตามกฎหมายในการจัดเก็บข้อมูลของบริษัทฯ 
                ตามที่ท่านยินยอมก่อนที่จะมีการเพิกถอนความยินยอมนั้น 
                เพื่อปกป้องความเป็นส่วนตัวและความปลอดภัยของท่าน 
                บริษัทฯจะทำการตรวจสอบตัวตนของท่านก่อนตอบกลับคำขอของท่านและจะตอบกลับภายในกรอบเวลาที่เหมาะสมบริษัทฯ 
                อาจไม่อนุญาตให้ท่านเข้าถึงข้อมูลส่วนตัวบางอย่างในบางกรณีเช่น 
                ข้อมูลส่วนตัวของท่านที่เกี่ยวโยงกับข้อมูลส่วนตัวของบุคคลอื่น 
                หรือด้วยเหตุผลทางกฎหมาย ในกรณีเช่นนี้บริษัทฯ 
                จะแจ้งให้ท่านทราบถึงเหตุผลที่ท่านไม่สามารถขอรับข้อมูลดังกล่าวได้ 
                บริษัทฯอาจปฏิเสธคำขอของท่านในการลบหรือแก้ไขข้อมูลส่วนตัวของท่าน 
                เนื่องจากบทบัญญัติตามกฎหมายโดยเฉพาะคำขอที่มีผลต่อการดำเนินการทางบัญชีของเรา 
                การดำเนินการเรียกร้อง 
                การดำเนินการเพื่อวัตถุประสงค์เกี่ยวกับการป้องกันและการตรวจจับการฉ้อฉล 
                และการเก็บรักษาข้อมูลที่ต้องกระทำ ซึ่งอาจไม่อนุญาตให้ลบข้อมูล 
                หรือการทำข้อมูลให้เป็นนิรนาม
                </div>
              <div className="number-title-48">
                • สิทธิในการให้โอนย้ายข้อมูล: 
                ท่านมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลของท่านที่ท่านได้ให้ไว้กับบริษัทฯ 
                ไปยังผู้ควบคุมข้อมูลรายอื่น หรือตัวท่านเองด้วยเหตุบางประการได้
                </div>
              <div className="number-title-48">
                •
                สิทธิในการคัดค้านการประมวลผลข้อมูล: 
                ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านได้ทุกเมื่อ 
                ทั้งนี้ บริษัทฯจะตรวจสอบและพิจารณาคำร้องของท่าน 
                และหากปรากฏว่าการประมวลผลข้อมูลของท่านมีความจำเป็น และ/หรือ 
                บริษัทฯมีสิทธิปฏิเสธคำร้องของท่านตามกฎหมาย 
                บริษัทจะแจ้งผลการพิจารณาคำร้อง 
                และเหตุผลในการปฏิเสธคำร้องดังกล่าวให้ท่านทราบภายในเวลาที่เหมาะสม
                </div>

              <div className="number-title-32">
                9. คุกกี้
                </div>
              <div className="number-title-48">
                ระบบของบริษัทฯ มีการใช้คุกกี้ 
                หรืออนุญาตให้บุคคลที่สามใส่คุกกี้ (ทั้งประเภท Session และ 
                Persistent), พิกเซล/แท็ก, ชุดพัฒนาซอฟต์แวร์ (SDK), แอพพลิเคชั่น 
                โปรแกรม อินเตอร์เฟซ (“API”), ส่วนวิเคราะห์ข้อมูลการใช้งาน 
                (Google Analytics) และเทคโนโลยีอื่นๆ (รวมเรียกว่า “คุกกี้”) 
                บนระบบของบริษัทฯ ซึ่งรวบรวมและจัดเก็บข้อมูลบางอย่างเกี่ยวกับท่าน 
                คุกกี้บางคุกกี้เหล่านี้มีความจำเป็นสำหรับการจัดหา 
                ดูแลความปลอดภัย และบำรุงการรักษาระบบให้แก่ท่าน เช่น 
                คงสถานการณ์เข้าสู่ระบบของท่านไว้ขณะที่ท่านเข้าใช้งานระบบ 
                ขณะที่มีการใช้งานคุกกี้อื่นๆ 
                เพื่อมอบประการณ์การใช้งานระบบที่ดียิ่งขึ้นให้แก่ท่าน เช่น:
                </div>

              <div className="number-title-48">
                •
                การวิเคราะห์ข้อมูล เช่น กิจกรรมการใช้งานระบบ ข้อมูลการแสดงผล 
                รายงานข้อมูลประชากร และรายงานข้อมูลความสนใจ 
                เพื่อปรับปรุงระบบและบริการ เพื่ออาจลงโฆษณาที่ท่านสนใจโดยเฉพาะ
                </div>
              <div className="number-title-48">
                •
                เพื่อแสดงโปรโมชันที่ท่านสนใจโดยเฉพาะ 
                บริษัทฯยังอนุญาตให้บุคคลที่สามบางรายใส่คุกกี้บนระบบของบริษัทฯ 
                เพื่อรวบรวมข้อมูลเกี่ยวกับกิจกรรมของท่านบนระบบของบริษัทฯ (เช่น 
                หน้าที่ท่านเยี่ยมชมบนระบบของบริษัทฯ และข้อมูลการค้นหาของท่าน) 
                ในระยะเวลาหนึ่ง และจากเว็บไซต์ต่างๆ ที่ท่านไปเยือน 
                ข้อมูลนี้จะใช้สำหรับโฆษณาของบริษัทฯ 
                ที่ออกแบบให้ตรงกับความสนใจของท่านโดยเฉพาะ (ผ่านทางอีเมล 
                ระบบของบริษัทฯ และเว็บไซต์อื่นๆ) 
                และจากบุคคลที่สามบนเว็บไซต์อื่นๆ ที่ท่านอาจเยี่ยมชม
                (เรียกโดยทั่วไปว่า การโฆษณาที่อิงตามความสนใจของผู้บริโภค) 
                และเพื่อวิเคราะห์ประสิทธิภาพของโฆษณาที่อิงตามความสนใจของผู้บริโภคดังกล่าว 
                บริษัทฯ ยังอาจแบ่งปันข้อมูลแฮชทางเดียว (One-way hashed 
                information) ซึ่งอาจรวมข้อมูลแฮชกับตัวบ่งชี้อื่นๆ 
                เพื่อให้บริการโฆษณาของบริษัทที่อิงตามความสนใจส่วนบุคคล 
                บนเว็บไซต์อื่นๆ หรือบนแอพพลิเคชั่นของอุปกรณ์พกพา 
                โดยอ้างอิงจากการเยี่ยมชมระบบของท่านในครั้งก่อนหน้า บริษัทฯ 
                มิได้สนับสนุนหรือรับรองจุดมุ่งหมาย อุดมการณ์ 
                หรือคำประกาศของเว็บไซต์หรือแอพพลิเคชั่นใดๆ 
                ที่แสดงโฆษณาของบริษัทฯ การติดตามข้ามอุปกรณ์ บริษัทฯ 
                อาจนำข้อมูลที่รวบรวมผ่านคุกกี้และข้อมูลเกี่ยวกับการใช้งานบางประการจากบางเบราว์เซอร์หรืออุปกรณ์พกพาไปรวมกับคอมพิวเตอร์หรืออุปกรณ์อีกเครื่องที่อาจเชื่อมโยงกับท่าน 
                (การติดตามข้ามอุปกรณ์) เพื่อให้บริการแก่ท่านได้อย่างดีที่สุด 
                รวมถึงติดต่อสื่อสารและแสดงโฆษณาของบริษัทฯ 
                ที่ตรงกับความสนใจของท่าน 

                </div>

              <div className="number-title-32">
                10. การยกเลิกการรับข่าวสารจากบริษัทฯ
                </div>
              <div className="number-title-48">
                การปฏิเสธไม่รับข่าวสารทางการตลาดจากบริษัทฯที่อาจมีในอนาคตนั้น 
                ท่านสามารถปฏิเสธการรับข่าวสารทางการตลาดจากบริษัทฯได้ทุกเมื่อ 
                ด้วยการคลิกลิงก์ “ยกเลิกการรับจดหมายข่าว” 
                ในจดหมายข่าวในแต่ละฉบับ (ถ้ามี) หรือการสื่อสารอื่นๆ 
                ซึ่งท่านสามารถแจ้งยกเลิกได้ผ่านแอพพลิเคชั่น
                </div>

              <div className="number-title-32">
                11. การอัพเดตนโยบายความเป็นส่วนตัว
                </div>
              <div className="number-title-48">
                นโยบายความเป็นส่วนตัวนี้ได้ทำการปรับปรุงล่าสุดเมื่อวันที่ 10 
                พฤษภาคม 2564 โดยบริษัทฯ อาจทำการเปลี่ยนแปลง ปรับปรุง 
                หรือเพิ่มเติมนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว 
                การเปลี่ยนแปลงของนโยบายความเป็นส่วนตัวบนเว็บแอพพลิเคชั่น บริษัทฯ 
                จะแจ้งการเปลี่ยนแปลงให้ท่านทราบและสามารถตรวจสอบได้ตามที่ปรากฎบนเว็บแอพพลิเคชั่น 
                การที่ท่านใช้งานหรือใช้บริการบนระบบต่อไปหลังจากการเปลี่ยนแปลง 
                ปรับปรุง หรือเพิ่มเติมที่ทำขึ้นกับนโยบายความเป็นส่วนตัวนี้ 
                อันเป็นการแสดงเจตนาที่เพิกถอนไม่ได้ 
                โดยนโยบายความเป็นส่วนตัวที่อาจมีการเปลี่ยนแปลง ปรับปรุง 
                หรือเพิ่มเติมในภายหลัง 
                ถือเป็นส่วนหนึ่งของข้อกำหนดและเงื่อนไขการใช้งานบริการ 
                (“ข้อตกลง”) ด้วย 
                </div>

              <div className="number-title-32">
                12. การติดต่อ
                </div>
              <div className="number-title-48">
                หากท่านต้องการใช้สิทธิของท่านตามกฎหมาย 
                หรือตามนโยบายนี้ หรือกรณีที่ท่านมีข้อสงสัยเกี่ยวกับนโยบายนี้ 
                ท่านสามารถติดต่อบริษัทฯ ตามรายละเอียด และช่องทาง ดังนี้
                </div>
              <br />
              <div className="number-title-48">
                บริษัท ไอแคร์ เฮลท์ จำกัด
                </div>
              <div className="number-title-48">
                37,39 ซอยศิริเกษม 7 แขวงบางไผ่ 
                เขตบางแค กรุงเทพมหานคร
                </div>
              <div className="number-title-48">
                โทรศัพท์ 028070825
                </div>
              <div className="number-title-48">
                E-mail: 
                member@icarehealthshop.com
                </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
